import ApiService from '@/util/api.service'

const generateCameraForm = (value, form, values) => {
  ApiService({
    url: `/v2/video-servers/${value}`,
    method: 'get',
  }).then((item) => {
    const newSchema = {
      server: {
        label: 'Servidor',
        type: String,
        model: 'v2/video-servers',
        autocomplete: false,
        //  eslint-disable-next-line object-shorthand
        onChange: function (val, fields, vals) {
          if (val) {
            generateCameraForm(val, form, vals)
          }
        },
      },
    };
    item.metadata.forEach((meta) => {
      newSchema[meta.name] = {
        label: meta.label,
        type: window[meta.type] || String,
        defaultValue: values?.[meta.name],
        optional: meta.optional
      }
    })
    form.initSchema(newSchema);
  }).catch((err) => {
    console.log(err)
    alert('ERROR')
  })
}

export default {
  id: {
    type: Number,
    visibility: false,
  },
  name: {
    label: 'Nombre',
    type: String,
  },
  uniqueId: {
    label: 'Identificador',
    type: String,
  },
  servidor: {
    label: 'Servidor',
    type: String,
    options: [
      { id: 'legacy', name: 'Legacy' },
      { id: 'hls', name: 'HLS' },
    ],
  },
  city: {
    label: 'Localidad',
    type: String,
  },
  address: {
    label: 'Dirección',
    type: String,
    textarea: true,
  },
  _location: {
    label: 'Ubicación',
    type: String,
    location: true,
    fields: ['latitude', 'longitude'],
  },
  longitude: {
    label: 'Longitud',
    type: Number,
    visibility: false,
  },
  latitude: {
    label: 'Latitud',
    type: Number,
    visibility: false,
  },
  contact: {
    label: 'Contacto',
    type: String,
  },
  groupId: {
    label: 'Grupo',
    type: Number,
    optional: true,
    model: 'groups',
    module: 'cameras',
    autocomplete: false,
  },
  disabled: {
    label: 'Deshabilitado',
    type: Boolean,
    optional: true,
  },
  attributes: {
    label: 'Camaras',
    type: Array,
    title: (item) => {
      return item.name;
    },
    onChange: (val, field) => {
      field.attributes.value.forEach((attr, i) => {
        attr.name = 'dev' + i;
        attr.title = attr.title || attr.name;
      });
    },
    optional: true,
  },
  'attributes.$': {
    type: {
      server: {
        label: 'Servidor',
        type: String,
        model: 'v2/video-servers',
        autocomplete: false,
        //  eslint-disable-next-line object-shorthand
        onChange: function (val, fields, values) {
          if (val) {
            generateCameraForm(val, this, values)
          }
        },
      },
    },
  },
};
